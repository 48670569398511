@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.main { padding: 0px 0px 30px; }

.mainContent{
    min-height: calc(100vh - 315px);

    // cursor: url(../../../assets/images/hil/cusrsor.svg) 16 16, auto;
    
    // [data-light-mode="false"] & {
    //     cursor: url(../../../assets/images/hil/cursorDark.svg) 16 16, auto;
    // }
}